.d-block {
  display: block;
}

.appContainer {
  margin: 15px;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow-y: auto;
}

header {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.appContainer__main {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px 0;
}

.appContainer__main__phonePlaceholder {
  max-width: 290px;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 25px;
  color: #000;
  padding-bottom: 5px;
  letter-spacing: 2px;
  border-bottom: 3px solid #ee9f9f;
}

.appContainer__main__keyPadGrp {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 25px auto;
  max-width: 400px;
}

.appContainer__main__keyPadGrp > button {
  margin: 10px;
  flex: 0 1 auto;
  width: 100px;
  height: 100px;
  background: #ddd;
  border-radius: 100%;
  font-size: 20px;
  color: #000;
}

.appContainer__main__keyPadGrp > button:hover {
  background: #d1d0d0;
}

.appContainer__main__keyPadGrp--next {
  background: #ee9f9f !important;
}

.appContainer__newCustomer {
  margin: 15px 0;
}

.appContainer__main__finalScreen {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.consentText {
  text-align: center !important;
  max-width: 300px;
  margin: 0 auto !important;
  font-weight: 100 !important;
}

@media (max-width: 768px) {
  .appContainer__main {
    flex-flow: row wrap;
  }
}

@media (max-width: 400px) {
  .appContainer__main__keyPadGrp > button {
    width: 80px;
    height: 80px;
  }
}
